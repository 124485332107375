import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CategoryIcon from '@mui/icons-material/Category';

const CustomPages = [
  {
    title: 'Zara',
    href: '/zara-homepage',
    icon: <DashboardIcon />,
    key: 1
  },
  {
    title: 'Workies', 
    href: '/zara-showcase',
    icon: <CategoryIcon />,
    key: 2
  },
];

export default CustomPages;
