export const USERNAME = 'Username';
export const PASSWORD = 'Password';
export const VARIANT_OUTLINED = 'outlined';
export const SUBMIT = 'submit';
export const LOGIN_TITLE = "Hi, there!";
export const LOGIN_SUBTITLE = "Login to check out the changes! ;)";
export const UNAUTHORIZED_USER_WARNING = 'Unauthorized user. Please try again.';
export const GENERAL_ERROR = 'Something went wrong. Please try again.';
export const HTTP_FAILED = 'failed';
export const HTTP_SUCCESS = 'success';
export const LOGIN_SUBMIT = `Let me in!`;
export const LOGIN_OPEN_MODAL = `Let's go!`;
export const LOGIN_MODAL_TITLE = 'Login';
export const SCROLL = "scroll";
export const ERROR = "error";
export const PAGE_NOT_FOUND = "Page not found or unavailable :(";