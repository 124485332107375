import React, {useState, useEffect} from 'react';
import useWindowDimensions from '../../../helpers/UseWindowDimensions';
import navigationStyles from './stylesNavigation.module.scss';
import MobileNav from './MobileNavigation';
import FullWidthNav from './FullWidthNavigation';
import { SCROLL } from '../../../Constants/GeneralConstants';

export default function Navigation () {
    const [sticky, setSticky] = useState(false);
    const [mobileNav, setMobileNav] = useState(false);
    const { width } = useWindowDimensions();

    useEffect(() => {
      const handleScroll = () => {       
        if (window.pageYOffset > 80) {  
          if(!sticky){
            setSticky(true);
          }
        }else {
          if(sticky){
            setSticky(false);
          }
        }
      }
      window.addEventListener(SCROLL, handleScroll, { passive: false });

      return () => {
        window.removeEventListener(SCROLL, handleScroll);
      };
    }, [sticky]);

    useEffect(() => { 
      if (width < 600)
      {
        setMobileNav(true);
      }
      else {
        setMobileNav(false);
      }
    }, [width]);

    return (
      <nav className={`${sticky && navigationStyles.sticky}`}>
        <div className={`${navigationStyles.mainNav} ${sticky && navigationStyles.sticky}`}>   
          {
            mobileNav ? <MobileNav/> : <FullWidthNav/>
          }                
        </div> 
      </nav>  
    )
}