import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Link } from 'react-router-dom';
import CustomPages from './PagesInformation';
import navigationStyles from './stylesNavigation.module.scss';

const FullWidthNav = () => {
    return (
        <List>
            {CustomPages.map(page => (
                <div className={navigationStyles.mainNavItem} key={page.key}>
                    <ListItem disablePadding> 
                        <ListItemButton component="div"> 
                            <Link to={page.href} className={navigationStyles.tabLink} style={{ textDecoration: 'none' }}>
                                <span className={navigationStyles.listItem}>{page.title}</span>
                            </Link>
                        </ListItemButton>
                    </ListItem>
                </div>
            ))}
        </List>
    );
}

export default FullWidthNav;