import React, { useState } from "react";
import { Link } from 'react-router-dom';
import CustomPages from './PagesInformation';
import ReorderIcon from '@mui/icons-material/Reorder';
import { styled } from '@mui/system';
import {Button, SwipeableDrawer, ListItem, ListItemButton, List} from '@mui/material';

const StyledDrawer = styled('div')(() => ({
  width: 160,
  height: '100%',
  backgroundColor: '#484848',    
  paddingTop: '200%'
}));

const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
  fontFamily: 'Dancing Script',
  fontWeight: 500,
  fontSize: '140%',
  color: '#ccc1c1',
  '&:hover': {
    color: '#f4f4f4',
    borderBottom: '2px solid rgb(247, 244, 244)'
  }
}));

const MobileNav = () => {
    const [mobileMenu, openMobileMenu] = useState(false);

    const handleNavClose = () => {
        openMobileMenu(false);
    };

    const handleNavOpen = () => {            
        openMobileMenu(true);
    };

    return (
        <React.Fragment>
            <Button onClick={handleNavOpen}>
              <ReorderIcon style={{ color: "#F7F4F4" }}/>
            </Button>
            <SwipeableDrawer anchor="right" open={mobileMenu} onClose={handleNavClose}>
                <StyledDrawer>
                    <List>
                        {CustomPages.map(page => (
                            <ListItem key={page.title} disablePadding>                  
                                <ListItemButton>
                                    <StyledLink to={page.href}>
                                        <span>{page.title}</span>                    
                                    </StyledLink>
                                </ListItemButton>
                            </ListItem>
                        ))} 
                    </List>
                </StyledDrawer>
            </SwipeableDrawer>
        </React.Fragment>        
    );
}

export default MobileNav;