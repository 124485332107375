import React from 'react';
import { Route } from 'react-router-dom';

const DefaultRouteWithLayout = props => {
  const { layout: Layout, component: Component, path: Path } = props;

  return (
    <Route exact path={Path}>      	
      <Layout>        
        <Component/>
      </Layout>				
    </Route>   
  );
};

export default DefaultRouteWithLayout;