import React from 'react';
import Footer from '../assets/zaraProject/footer/Footer';
import Navigation from '../assets/zaraProject/navigation/Navigation';

export default function MainLayout(props){
  const { children } = props;
  
  return (
    <React.Fragment>
        <Navigation/>
            {children}
        <Footer/>
    </React.Fragment>
  );
};