import React from 'react';
import Routes from './Routes';
import { AnimatePresence } from 'framer-motion';

export default function App() {    
    return (  
      <div style={{ overflowX: "hidden", overflowY: "hidden"}}>
         <AnimatePresence exitBeforeEnter>
            <Routes/>
         </AnimatePresence>
      </div>      
   );
}