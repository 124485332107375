import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import ZaraWebLayout from './layouts/ZaraWebLayout';
import DefaultRouteWithLayout from './layouts/DefaultRouteWithLayout';
//import PersonalPage from './Pages/gvidasPages/PersonalPage';
//import StartPage from './Pages/zaraPages/StartPage';
//import WorksPage from './Pages/zaraPages/WorksPage';
//import NotFoundPage from './Pages/NotFoundPage';
//import AdminPage from './Pages/AdminPage';
//import LinaPage from './Pages/linasPages/LinaPage';
//import LinaSecondPage from './Pages/linasPages/LinaSecondPage';

const LinaPage = React.lazy(() => import('./Pages/linasPages/LinaPage'));
const LinaSecondPage = React.lazy(() => import('./Pages/linasPages/LinaSecondPage'));
const StartPage = React.lazy(() => import('./Pages/zaraPages/StartPage'));
const WorksPage = React.lazy(() => import('./Pages/zaraPages/WorksPage'));
const NotFoundPage = React.lazy(() => import('./Pages/NotFoundPage'));
const AdminPage = React.lazy(() => import('./Pages/AdminPage'));
const PersonalPage = React.lazy(() => import('./Pages/gvidasPages/Homepage'));

const ZARA_PROJECT = {
	aboutMePath: '/zara-homepage',
	worksPath: '/zara-showcase'
}
  
const LINA_PROJECT = {
	mainPagePath: '/welcomePage',
	secondPagePath: '/threeJS'
}

const GVIDAS_PROJECT = {
	mainPagePath: '/'
}
  
const DEFAULT = {
	allProjectsPath: '/admin',
	notFoundPath: '/not-found',
	defaultPath: '/'
}

const Routes = () => {		
	return (
		<Suspense fallback={null}>
			<Switch>										
				<Route exact path={DEFAULT.allProjectsPath} component={AdminPage}/>	
				<DefaultRouteWithLayout
					component={StartPage}
					layout={ZaraWebLayout}				
					path={ZARA_PROJECT.aboutMePath}				
					exact
				/>					 
				<DefaultRouteWithLayout
					component={WorksPage}
					layout={ZaraWebLayout}				
					path={ZARA_PROJECT.worksPath}
					exact
				/>
				<Route exact path={LINA_PROJECT.mainPagePath} component={LinaPage}/>
				<Route exact path={LINA_PROJECT.secondPagePath} component={LinaSecondPage}/>
				<Route exact path={DEFAULT.notFoundPath} component={NotFoundPage}/>
				<Route exact path={GVIDAS_PROJECT.mainPagePath} component={PersonalPage}/>
				<Route path='*' component={NotFoundPage}/>					
			</Switch>
		</Suspense>
		
	);
};

export default Routes;